import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { InformationTooltip } from '../tooltips';

const TitleAndInformationTooltip = ({ title, tooltipTitle, ...props }) => (
  <Typography {...props}>
    {title}
    {tooltipTitle && (
      <span style={{ marginLeft: '10px' }}>
        <InformationTooltip title={tooltipTitle} />
      </span>
    )}
  </Typography>
);

TitleAndInformationTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  tooltipTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

TitleAndInformationTooltip.defaultProps = {
  tooltipTitle: null,
};

export default TitleAndInformationTooltip;
